import { nanoid } from 'nanoid';

// WEBSITE Header Meta
export const headData = {
  title: 'Rishi Singh Bhachu | Software Engineer | Portfolio', // Website Name
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Portfolio Website for Software Engineer Rishi Singh Bhachu', // e.g: Welcome to my website
  // keywords: '' // to add??
  // google tag manager?
};

// HEADER DATA
export const headerData = {
  networks: [
    /*
    {
      id: nanoid(),
      name: 'Home',
      url: 'hero', // dont include # symbol
    },
    */
    {
      id: nanoid(),
      name: 'About Me',
      url: 'about',
    },
    {
      id: nanoid(),
      name: 'Experience',
      url: 'experience',
    },
    {
      id: nanoid(),
      name: 'Projects',
      url: 'projects',
    },
    {
      id: nanoid(),
      name: 'Contact',
      url: 'contact',
    }
  ],
  networks2: [
    {
      id: nanoid(),
      name: 'github',
      label: 'GitHub',
      url: 'https://github.com/rbhachu',
    },
    {
      id: nanoid(),
      name: 'stack-overflow',
      label: 'Stack Overflow',
      url: 'https://stackoverflow.com/users/5238978/juggy',
    }, 
    {
      id: nanoid(),
      name: 'hashtag',
      label: 'Hashnode',
      url: 'https://hashnode.com/@bhachublog',
    },  
    {
      id: nanoid(),
      name: 'linkedin',
      label: 'LinkedIn',
      url: 'https://www.linkedin.com/in/rishibhachu/',
    },
    {
      id: nanoid(),
      name: 'file-pdf-o',
      label: 'Download CV',
      url: 'https://drive.google.com/file/d/1QHLOlQOxR4NxK-iXpooyl6pdqMW_3E73/view?usp=sharing',
    },
  ],
};

// HERO DATA
export const heroData = {
  title: 'Welcome to the Portfolio Website of',
  name: 'Rishi Singh Bhachu',
  subtitle: 'Software Engineer 👨🏽‍💻',
  cta: 'more info', // link scrolls too About Me
};

// ABOUT DATA
export const aboutData = {
  img: "",
  img2: "",

  paragraphOne: "I am a <strong>UK-based Software Engineer</strong> with over <strong>ten years of web development experience</strong>, including <strong>three years specialising in React</strong>. I’ve collaborated with <strong>individuals, startups</strong>, and <strong>large organizations</strong> across <strong>diverse industries</strong>, consistently delivering <strong>high-performance, user-centered applications</strong>.",
  
  paragraphTwo: "My expertise spans <strong>test-driven development (TDD)</strong>, <strong>continuous integration and deployment (CI/CD)</strong>, and <strong>Agile methodologies</strong>, enabling me to build <strong>reliable, cross-platform solutions</strong> that enhance <strong>user experiences</strong>.",

  paragraphThree: "<strong>Adept in Agile environments</strong>, I apply <strong>best practices in UI/UX design</strong> to consistently deliver results that <strong>not only meet but exceed client expectations</strong>.",

  resume: "resume", // if no resume, the button will not show up
};

// EXPERIENCE DATA
export const experienceData = [
  { // WEBSITES 1
    id: nanoid(),
    title: 'Bezos 🌐',
    info: "Bezos.ai is a Fulfillment-as-a-Service start-up (3PL) specializing in e-commerce logistics. It provides online sellers with services such as storage, picking, packing, shipping, and returns management. Operating in the UK, US, Germany, and the Netherlands, it integrates with over 30 platforms, including Shopify, Amazon, and WooCommerce",
    
    infoExtended: "Spearheading front-end development for two of the companies key platforms: The internal Operations Portal (admin) and Seller Portal (client). Building reusable and scalable shared components across the platforms to integrate with RESTful APIs in GO. Delivered via incremental updates using TDD and CI/CD processes with React Testing Library for unit and integration testing, ensuring continuous improvement, scalability and user satisfaction through weekly sprints and code reviews.<br><br>For further details please see resume.",
    
    resume: "resume", // if no resume, the button will not show up
    info2: [
      "HTML5", 
      "CSS3", 
      "SASS", 
      "React", 
      "TypeScript",
      "Redux",
      "Material UI", 
      "date-fns",
      "Formik",
      "Yup",
      "Axios",
      "Lodash",
      "React Testing Library",
      "Jest",
      "REST API", 
      "Go",
      "PostgreSQL",
      "AWS",
      "Figma",
      "Responsive Layout", 
      "Google Analytics", 
      "Google Tag Manager", 
      "TDD", 
      "CI/CD",
    ],      
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'websites-bezos-ops1.png',
    alt: 'Operations Portal - Charts / Data',
    img2: 'websites-bezos-ops2.png',
    alt2: 'Operations Portal - Date picker / Colomn configuration',
    img3: 'websites-bezos-ops3.png',
    alt3: 'Operations Portal - Modals',
    img4: 'websites-bezos-seller1.png',
    alt4: 'Seller Portal - Filters / Modals / Buttons',
    img5: 'websites-bezos-seller2.png',
    alt5: 'Seller Portal - Light / Dark Modes',
    img6: 'websites-bezos-seller3.png',
    alt6: 'Seller Portal - Formik / Yup Validation',
    img7: 'websites-bezos-seller4.png',
    alt7: 'Seller Portal - Page Loader / Image view',
    img8: 'websites-bezos-seller5.png',
    alt8: 'Seller Portal - Responsive Side Nav / Layout',
  },
  { // WEBSITES 2
    id: nanoid(),
    title: 'Canadian Affair / Air Transat 🌐',
    info: "Canadian Affair is the UK's largest tour operator to Canada, providing services including flights, package holidays, cruise lines, hotels, motorhome hire and car hire. It is owned and operated by its parent company Air Transat based in Montréal, Québec in Canada.",
    
    infoExtended: "I managed the company's UK, European and Canadian websites, including their WordPress blog sites, overseeing the full lifecycle from design and development to ongoing maintenance. This involved working with external product teams and third-party developers, managing image assets, resolving bugs, optimizing pages, and conducting A/B testing, with regular reporting on user activity and conversions.<br><br>Additionally, I led the company's email campaign platform, managing responsive templates and ensuring effective deployment of campaigns.<br><br>For further details please review my resume.",
    info2: ["HTML", "CSS", "JavaScript", "JQuery", "Soap API", "XML", "Responsive Layout", "Multilingual", "Classic ASP", "ASP.NET", "SQL", "Oracle Database", "PostCode Anywhere", "Adobe CC", "TDD", "CI/CD", "Google Analytics", "Google Tag Manager"],
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'websites-caf1.png',
    alt: 'Canadian Affair UK Homepage & Flight Search',
    img2: 'websites-caf2.png',
    alt2: 'Canadian Affair UK Holidays, Hotels, Excursions & Car Hire',
    img3: 'websites-ca-ie-aaf.jpg',
    alt3: 'Canadian Affair Ireland, Canada & American Affair',
    img4: 'websites-affairtravel.jpg',
    alt4: 'Affair Travel Villas',
    img5: 'websites-transat.jpg',
    alt5: 'Air Transat UK, Ireland, Italy, Portugal, Spain & Greece',
    img6: '',
    alt6: '',
    img7: '',
    alt7: '',
    img8: '',
    alt8: '',
  },
  { // WORDPRESS
    id: nanoid(),
    title: 'WordPress',
    info: "Management and Development for the Canadian Affair & American Affair WordPress Blogs. Hosted via WP Engine.",
    info2: ["WordPress", "WP Engine", "PHP", "WordPress Plugins", "CMS"],
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'wordpress-caf1.jpg',
    alt: 'Canadian Affair WordPress Blog',
    img2: 'wordpress-aaf1.png',
    alt2: 'American Affair WordPress Blog',
    img3: 'wordpress-caf2.jpg',
    alt3: 'WordPress Blogs hosted via WP Engine with Staging',
    img4: '',
    alt4: '',
    img5: '',
    alt5: '',
    img6: '',
    alt6: '',
    img7: '',
    alt7: '',
    img8: '',
    alt8: '',
  },
  { // HTML EMAILERS
    id: nanoid(),
    title: 'Emailers 📥',
    info: "Gallery of HTML Responsive Emailers including Promotional, BAU, Upsell and Advisory types. Multi-Client / Browser / Device Tested with Domain Deliverability & Reputation Monitoring / Testing.",
    info2: ["HTML", "CSS", "Responsive Email Templates", "Oracle Responsys", "Campaign Monitor", "MailChimp / Mandrill", "MX Toolbox"],
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'emailers-caf1.png',
    alt: 'Canadian Affair Special Offers',
    img2: 'emailers-caf3.png',
    alt2: 'Canadian Affair Special Offers',
    img3: 'emailers-caf2.png',
    alt3: 'Canadian Affair Count Down Sale',
    img4: 'emailers-caf4.png',
    alt4: 'Canadian Affair Upsell',
    img5: 'emailers-caf5.png',
    alt5: 'Canadian Affair Flight Schedule Change Template for Responsys',
    img6: 'emailers-affair1.png',
    alt6: 'Affair Travel Villas',
    img7: 'emailers-affair2.png',
    alt7: 'Affair Travel Villas',
    img8: '',
    alt8: '',
  },
  { // EMAIL AUTOMATION
    id: nanoid(),
    title: 'Email Automation 🛠️',
    info: "Creation of various automated Programmes within Oracle Responsys Email Automation Platform. Including Welcome Sign-up, BAU, Upsell and Re-Permission Campaigns. Capturing user website activity via Responsys API & GTM.",
    info2: [
      "Oracle Responsys", 
      "RPL", 
      "Email Automation", 
      "Email Programmes / Campaigns", 
      "Responsive Email Templates", 
      "Data Capture", 
      "Triggered Events",
      "Google Analytics", 
      "Google Tag Manager", 
    ],
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'experience-responsys3.png',
    alt: 'Responsys Welcome Sign-up Automated Program',
    img2: 'experience-responsys1.png',
    alt2: 'Responsys Abandoned Cart Automated Program',
    img3: 'experience-responsys2.png',
    alt3: 'Responsys Upsell Automated Program',
    img4: 'experience-responsys4.png',
    alt4: 'Responsys Re-Permission / GDPR Program',
    img5: '',
    alt5: '',
    img6: '',
    alt6: '',
    img7: '',
    alt7: '',
    img8: '',
    alt8: '',
  },
  { // RICHIBILLIONS
    id: nanoid(),
    title: 'Richie Billions',
    info: "Richie Billions was a Reflective Streetwear clothing start-up I created solely in 2018. Consisting of unique T-Shirt designs which `illuminate` when LED lighting is present (camera / phone flash or in a Nightclub).<br>The website was hosted on the Shopify platform using a custom theme I had created based on the native Shopify Liquid (Ruby based) coding language + CSS / SASS.<br>I also created the promotional media for the website and social media platforms with deep links. Including a promotional video created just using my Samsung S7 Edge Phone, with the final edit composed and completed with Adobe Premiere & Audition.",
    info2: [
      "HTML",
      "SASS",
      "JavaScript",
      "Liquid (Ruby)",
      "Shopify", 
      "Adobe Premiere",
      "Adobe Audtion",
      "Adobe Photoshop",
      "Adobe Illustrator",
      "Photography", 
      "Videography (Greenscreen / Chroma Key)", 
      "Video Editing",
      "DJ / Audio", 
      "SEO", 
      "Marketing", 
      "Merchandising", 
      "Garment Production", 
      "Packaging",
      "YouTube",
      "Facebook", 
      "Instagram", 
    ],    
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'projects-rb-video.png',
    alt: 'Richie Billions Promotional Video',
    img2: 'projects-rb-promo1.jpg',
    alt2: 'Website, Social Media Promo / Campaigns',
    img3: 'projects-rb-promo2.jpg',
    alt3: 'Website, Social Media Promo / Campaigns',
    img4: 'projects-rb-web-front.jpg',
    alt4: 'Website Frontend - Responsive, Mobile Optimised',
    img5: 'projects-rb-web-back.jpg',
    alt5: 'Website Backend - Shopify CMS, Liquid (Ruby) + SASS Code Template Editing',
    img6: 'projects-rb-behind.jpg',
    alt6: 'Behind The Scenes',
    img7: '',
    alt7: '',
    img8: '',
    alt8: '',
  }
];

// PROJECTS DATA
export const projectsData = [
  { // MOVIESEARCH
    id: nanoid(),
    title: 'Movie Database 🎞️',
    info: "A responsive movie search website that enables users to search for and view detailed information about their favorite movies, leveraging The Movie Database API via RESTful calls.",
    info2: [
      "HTML5", 
      "CSS3", 
      "CSS FlexBox", 
      "SASS", 
      "React", 
      "Node", 
      "Express", 
      "The Movie DB API", 
      "REST API", 
      "SPA", 
      "PWA", 
      "Netlify"
    ],
    url: 'https://rbhachu-react-movie-search.netlify.app', // if no link, the button will not show up
    repo: 'https://github.com/rbhachu/movie-search', // if no repo, the button will not show up
    img: 'projects-movie.png',
    alt: 'Movie Search App with Responsive Layout',
    img2: 'projects-movie2.png',
    alt2: 'Pop-out Responsive Menu featuring `Top Searches` Deep Links',
    img3: 'projects-movie3.png',
    alt3: 'Resposive Animated Flip Cards, displaying Full Movie Titles & Descriptions',
    img4: 'projects-movie4.png',
    alt4: 'Custom User-Friendly Error Messages',
    img5: 'projects-movie5.png',
    alt5: 'Resposive Mobile Layout with Fixed (portrait) & Un-Fixed Header (landscape)',
    img6: 'projects-movie6.png',
    alt6: 'Github Repo to Netlify Hosting - Continuous Deployment (CD)',
    img7: '',
    alt7: '',
    img8: '',
    alt8: '',
  },
  { // SMARTBRAIN
    id: nanoid(),
    title: 'Smart Brain 🧠',
    info: 'A website that detects, then highlights faces in web images. Including a user profile with image upload count & ranking badge served from AWS Lambda as a serverless function.<br><br><i>(Test Login Email: a@a.com / Pass: a)</i>',
    info2: [
      "HTML5", 
      "CSS3", 
      "FlexBox", 
      "Tachyon CSS",
      "JSON Web Tokens", 
      "bcrypt",  
      "Form Validation",
      "React", 
      "Node", 
      "Express", 
      "Clarifai Face Detection API", 
      "REST API", 
      "Redis", 
      "AWS Lambda", 
      "PostgreSQL", 
      "Docker",
      "Heroku",
      "SPA", 
      "PWA", 
    ],    
    url: 'https://rbhachu-smartbrain-f-master.herokuapp.com', // if no link, the button will not show up
    repo: 'https://github.com/rbhachu/smartbrain-frontend-jwt', // if no repo, the button will not show up
    img: 'projects-smartbrain.png',
    alt: 'Smart Brain App with Responsive Layout',
    img2: 'projects-smartbrain2.png',
    alt2: 'User Profile with Rank, Total Images Uploaded, Date Joined and Edit Name Feature',
    img3: 'projects-smartbrain3.png',
    alt3: 'Sign-in & Register Form Validation',
    img4: 'projects-smartbrain4.png',
    alt4: 'Rank Badge served from external AWS Lambda Serverless',
    img5: 'projects-smartbrain5.png',
    alt5: 'User Profile Authentication / Session / Encryption - PostgreSQL, Redis, JSON Web Tokens, bcrypt',
    img6: 'projects-smartbrain6.png',
    alt6: 'Logo Hover Tilt Effect, Background Particles Effect, Custom Fav Ico, Tabbed Interface, Multi-Face Detection, PWA Enabled',
    img7: 'projects-smartbrain7.png',
    alt7: 'Github Repo to Heroku Hosting - Continuous Deployment (CD)',
    img8: '',
    alt8: '',
  },
  { // ROBOFRIENDS
    id: nanoid(),
    title: 'RoboFriends 🤖',
    info: 'A simple responsive website which allows users to filter the Robots results data via the search form.',
    info2: [
      "HTML5", 
      "CSS3", 
      "Tachyon CSS", 
      "React", 
      "Redux", 
      "Node", 
      "Express", 
      "REST API", 
      "Enzyme", 
      "Netlify",
      "SPA", 
      "PWA", 
    ],
    url: 'https://rbhachu-robofriends.netlify.app', // if no link, the button will not show up
    repo: 'https://github.com/rbhachu/robofriends', // if no repo, the button will not show up
    img: 'projects-robofriends.png',
    alt: 'RoboFriends App with Responsive Layout',
    img2: 'projects-robofriends2.png',
    alt2: 'Real-Time Results Filter',
    img3: 'projects-robofriends3.png',
    alt3: 'Github Repo to Netlify Hosting - Continuous Deployment (CD)',
    img4: '',
    alt4: '',
    img5: '',
    alt5: '',
    img6: '',
    alt6: '',
    img7: '',
    alt7: '',
    img8: '',
    alt8: '',
  },
  { // MOJOBLOG
    id: nanoid(),
    title: 'The Mojo Blog 📓',
    info: "A blog site that allows users to create and manage posts via a REST API connected to a JSON Server. Pagination and redirects are efficiently managed with React Router DOM",
    info2: [
      "HTML5", 
      "CSS3", 
      "Tachyon CSS", 
      "React", 
      "React Router", 
      "Node", 
      "Express", 
      "REST API", 
      "JSON Server", 
      "Heroku",
      "SPA", 
      "PWA", 
    ],    
    url: 'https://rbhachu-the-mojo-blog.herokuapp.com', // if no link, the button will not show up
    repo: 'https://github.com/rbhachu/the-mojo-blog', // if no repo, the button will not show up
    img: 'projects-themojoblog.png',
    alt: 'Mojo Blog App with Responsive Layout',
    img2: 'projects-themojoblog2.png',
    alt2: 'Create New Blog Post & Select Blog Author',
    img3: 'projects-themojoblog3.png',
    alt3: 'View and Delete Blog Posts via dedicated post urls',
    img4: 'projects-themojoblog4.png',
    alt4: 'View All Blog Posts',
    img5: 'projects-themojoblog5.png',
    alt5: 'Custom Error 404 Page',
    img6: 'projects-themojoblog6.png',
    alt6: 'Github Repo to Heroku Hosting - Continuous Deployment (CD)',
    img7: '',
    alt7: '',
    img8: '',
    alt8: '',
  },
  { // MAMEARCADE
    id: nanoid(),
    title: 'MAME Arcade Machine 🕹️',
    info: 'In a fun personal project, I refurbished my retro MAME arcade machine, upgrading the internal PC, replacing the CRT to a more modern LCD panel and installing illuminated controls with a new layout.<br><br>The software was updated to run LaunchBox, supporting over 6,500 retro games and emulated consoles (SNES, MegaDrive, PlayStation), including classics like Street Fighter and Double Dragon.<br><br>An additional update was also made to allow support to run more modern games such as Ultra Street Fighter IV with online play via a Steam plugin.',
    info2: [
      "DIY",
      "Electronics",
      "MAME",
      "Sanwa Joysticks / Buttons",
      "iPac Controller",
      "LED Lighting",
      "LaunchBox Emulation Software",
      "STEAM",
    ],    
    url: '', // if no link, the button will not show up
    repo: '', // if no repo, the button will not show up
    img: 'bhachucade3000.jpg',
    alt: 'BhachuCade 3000 - Retro Mame Arcade Machine',
    img2: 'bhachucade-completed.jpg',
    alt2: 'Mame Arcade Machine Completed Build',
    img3: '',
    alt3: '',
    img4: '',
    alt4: '',
    img5: '',
    alt5: '',
    img6: '',
    alt6: '',
    img7: '',
    alt7: '',
    img8: '',
    alt8: '',
  }
];

// CONTACT DATA
export const contactData = {
  img: 'icons_avataar/avataaars-lookup.png',
  // cta: 'To <b>contact me</b> please drop me a message via <b>Email</b> or <b>LinkedIn</b>',
  cta: 'To contact me please use the form below, and I will aim to get back to you promplty, thanks!',
  cta2: 'Thank you, your message has been submitted successfully, and I will aim to get back to you shortly.',
  cta3: 'Error Sending Message!<br>Please contact me via email: <u>mr_bhachu@hotmail.com</u>',
  //cta3: '<span className="alert">Error Sending Message!<br>Please contact me via email: <a target="_blank" rel="noopener noreferrer" class="contact-button" href="mailto:mr_bhachu@hotmail.com">mr_bhachu@hotmail.com</a></span>',
  btn: 'Go to LinkedIn',                                                               
  email: 'mr_bhachu@hotmail.com',
};

// FOOTER DATA
// Icons list Reference: https://mdbootstrap.com/docs/react/content/icons-list/
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/rbhachu',
    },
    {
      id: nanoid(),
      name: 'stack-overflow',
      url: 'https://stackoverflow.com/users/5238978/juggy',
    }, 
    {
      id: nanoid(),
      name: 'hashtag',
      url: 'https://hashnode.com/@bhachublog',
    },  
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/rishibhachu/',
    },
  ],
};